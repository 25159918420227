import {createContext,useState} from "react";

import data from "../data.json";

export const TableContext = createContext(null);

export const TableProvider = ({children}) => {

    // localStorage.removeItem('data');

    const [table,setTable] = useState(
        localStorage.getItem('data')
        ?JSON.parse(localStorage.getItem('data'))
        :data
    );

    const clearData = () =>{
        localStorage.removeItem('data');
        setTable(data);
    }

    const changeSapp = (id,idDisease) => {
        const newData = table.map(item => 
            item.id === id ? { ...item,sapp:idDisease} : item
        )
        setTable(newData);
    }

    const value = {
        table,
        setTable,
        changeSapp,
        clearData
    }

    return <TableContext.Provider value={value}>
        {children}
    </TableContext.Provider>
}