import React, { useEffect } from 'react';
import ReactDOM from "react-dom";
import styled from 'styled-components';

const Modal = ({ isOpen, onClose, children }) => {
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContainer className="overflow" style={{maxHeight:'100%'}}>
        <CloseButton onClick={onClose}/>
        {children}
      </ModalContainer>
    </ModalOverlay>,
    document.getElementById("modal-root") // Render into the modal-root div
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  padding:20px;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: #fff;
  border-radius: 24px;
  padding: 24px;
  max-width:800px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.div`
    height:16px;
    width:16px;
    position:absolute;
    top:15px;
    right:15px;
    background:url(/img/close.svg) no-repeat 50% 50%;
    background-size:cover;
    cursor:pointer;
`

export {Modal};
