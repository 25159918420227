import { useTable } from "../hook/useTable";
import { Line } from "./Line";

const Category = ({data,categoryId}) => {

    // const change = (desease,input) =>{
    //     changeItem(data.id,desease,input);
    // }

    return (
        <tbody>
            <tr>
                {/* <td className="table-sticky-h table-category"></td> */}
                <td className="table-sticky-h table-category table-second">
                    <span></span>
                    {data.title}
                </td>
                <td colSpan="100%" className="table-line"></td>
            </tr>
            {data.desease.map((item, index) => (
                <Line key={index} data={item} diseaseId={item.id} categoryId={categoryId} sapp={data.sapp}/>
            ))}
        </tbody>
    )
}

export {Category}