import Select from "react-select";

function SelectInput({ data,setData,placeholder, options }) {

  return (
    <Select
      options={options}
      value={data != null?options.filter(item=>item.label == data):null}
      onChange={setData}
      menuPosition="fixed"
      placeholder={placeholder}
      classNamePrefix="my-select"
    />
  );
}

export default SelectInput;
