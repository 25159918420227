import React, { useMemo, useState } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";

function CountrySelector({ data, setData, placeholder}) {

  const options = useMemo(() => countryList().getData(), []);

  // const [state,setState] = useState(null);

  // const changeCountry = data =>{
    
  // }

  return (
    <Select
      options={options}
      value={data != null?options.filter(item=>item.label == data):null}
      onChange={setData}
      menuPosition="fixed"
      placeholder={placeholder}
      classNamePrefix="my-select"
    />
  );
}

export default CountrySelector;
