import { useEffect } from "react";

const List = ({data,delItem,setIsListOpen,openEdit}) =>{

    useEffect(()=>{
      if(!data.selected.length){
        setIsListOpen(false)
      }
    },[data])

    return (
        <div>
            <h5>{data.title}</h5>
            <div className="overflow" style={{maxWidth:"600px"}}>
              <table className="table-list">
                <thead>
                  <tr>
                    <th rowSpan="2">Route of administration</th>
                    <th rowSpan="2">Registration status</th>
                    <th style={{width:"200px",minWidth:"200px"}} rowSpan="2">Country(ies) of distribution of exclusivity</th>
                    <th style={{width:"200px",minWidth:"200px"}} rowSpan="2">Country(ies) of drug registration</th>
                    <th style={{width:"200px",minWidth:"200px"}} rowSpan="2">CTA</th>
                    <th style={{width:"200px",minWidth:"200px"}} colSpan="3" className="pb-0">Release of the drug</th>
                    <th rowSpan="2"></th>
                  </tr>
                  <tr>
                    <th>A)</th>
                    <th>B)</th>
                    <th>С)</th>
                  </tr>
                </thead>
                <tbody>
                {data.selected.map((item,key)=>{
                  const dataEdit = {index:key,...item};
                  return <tr key={key}>
                      <td><div className="text-truncate">{item.route}</div></td>
                      <td><div className="text-truncate">{item.status}</div></td>
                      <td>{item.cdis}</td>
                      <td>{item.creg}</td>
                      <td>
                        <div className="text-truncate">
                        {
                          {
                            0:'Provide the dossiers required',
                            1:'Carry out a turnkey project'
                          }[item.sta]
                        }
                        </div>
                      </td>
                      <td>{item.ra?<div className="table-line-check"></div>:''}</td>
                      <td>{item.rb?<div className="table-line-check"></div>:''}</td>
                      <td>{item.rc?<div className="table-line-check"></div>:''}</td>
                      <td>
                        <div className="d-flex gap-2">
                          <div className="table-icon cursor" onClick={()=>{openEdit(dataEdit);setIsListOpen(false)}}><img src="/img/edit.png"/></div>
                          <div className="table-icon cursor" onClick={()=>delItem(key)}><img src="/img/del.png"/></div>
                        </div>
                      </td>
                  </tr>
                })}
                </tbody>
              </table>
            </div>
          </div>
    )
}

export {List}