import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CountrySelector from './CountrySelector';
import axios from 'axios';
import { useTable } from '../hook/useTable';

const SendForm = ({ isOpen, onClose}) => {

  // useEffect(() => {
  //   // Load reCAPTCHA script
  //   const script = document.createElement('script');
  //   script.src = 'https://www.google.com/recaptcha/enterprise.js';
  //   script.async = true;
  //   script.defer = true;
  //   document.body.appendChild(script);
  // }, []);

  const {table,clearData} = useTable();

  const [load,setLoad] = useState(false);

  const filteredData = table.map(item => {
    const filteredDiseases = item.desease.filter(des => des.check === true);
    return {
        ...item,
        desease: filteredDiseases
    };
  }).filter(item => item.desease.length > 0);

  // const filteredData = table;

  const def = {
    fname:'',
    lname:'',
    com:'',
    comcode:'',
    country:null,
    phone:'',
    email:'',
  }

  const [send,setSend] = useState(false);
  const [form,setForm] = useState(def);

  const [errors, setErrors] = useState({});

  const validate = () => {
    let errors = {};
    
    const requiredFields = ['fname', 'lname', 'com', 'comcode', 'phone', 'email'];
    for (let field of requiredFields) {
      if (form[field] === undefined || !form[field].trim()) {
        errors[field] = `Field is required`;
      }
    }

    if (!form.country) {
      errors.country = "Country choise is required";
    }
    
    if (!form.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      errors.email = "Email is invalid";
    }

    if (!form.phone) {
      errors.phone = "Phone is required";
    } else if (!/^\+?\d{1,15}$/.test(form.phone)) {
      errors.phone = "Phone number is invalid. Only digits and '+' are allowed";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const changeCountry = ({label}) =>{
    setErrors({});
    setForm(prev => ({...prev,country: label}))
  }

  const changeForm = (e) =>{
    setErrors({});
    form[e.target.name] = e.target.value;
    setForm({...form});
  }
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      setSend(false);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  // const sendForm = () => {
  //   const filteredData = table.map(item => {
  //     const filteredDiseases = item.desease.filter(des => des.check === true);
  //     return {
  //         ...item,
  //         desease: filteredDiseases
  //     };
  //   }).filter(item => item.desease.length > 0);
  // }

  const sendForm = async () => {

    if(validate()){
      if (window.grecaptcha) {
        await new Promise((resolve) => {
            window.grecaptcha.enterprise.ready(() => {
                resolve();
            });
        });

        setLoad(true);
        const token = await window.grecaptcha.enterprise.execute('6Lf9RHUqAAAAAF7jFpcTL0bDueIGn2CGjLabfy3-', { action: 'LOGIN' });

        const reqData = {table:[...filteredData],...form,token};

        axios.post('https://schali.swiss/mainform.php',reqData,{
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(({data}) => {
          if(data != ''){
            let errors = {}
            errors.send = 'Sorry! Send error';
            setErrors(errors);
          }else{
            setForm(def);
            clearData();
            setSend(true);
          }
          setLoad(false);
        })
        .catch((err) => {
          console.error('Error:', err);
        });
        
      }
    }
  }

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContainer className="overflow" style={{maxHeight:'100%'}}>
        <CloseButton onClick={onClose}/>
        {!send?
          <form className={`d-flex gap-4 align-items-start flex-column flex-md-row ${load && '_load'}`}>
              <div className="p-4 p-md-5 pe-md-0 flex-grow-1">
                  <h3 className='mb-3'>Your choice</h3>
                  <div>
                      {filteredData.length
                        ?
                          filteredData.map((item,key)=>
                            <div key={key} className='my-2'>
                              <div className='my-1'><b>{item.title}</b></div>
                              <div className="d-flex flex-column gap-2 overflow">
                                {item.desease.map((item,key)=>(
                                  <div className='d-flex gap-2' key={key}>
                                      <img src="/img/check.svg" width={9}/>
                                      <Desease>{item.title}</Desease>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )
                        :<div>No choise</div>
                      }
                  </div>
              </div>
              <div className="p-4 pt-0 p-md-5 ps-md-0 flex-grow-1">
                  <h3>Contact information</h3>
                  <div className="d-flex flex-column gap-2">
                      <div className='d-flex gap-3 flex-column flex-md-row'>
                        <div className="w-100">
                            <label>First Name</label>
                            <input className="inputs w-100" name="fname" value={form.fname} onChange={changeForm}/>
                            {errors.fname && <div className='error'>{errors.fname}</div>}
                        </div>
                        <div className="w-100">
                            <label>Last Name</label>
                            <input className="inputs w-100" name="lname" value={form.lname} onChange={changeForm}/>
                            {errors.lname && <div className='error'>{errors.lname}</div>}
                        </div>
                      </div>
                      <div className='d-flex gap-3 flex-column flex-md-row'>
                        <div className="w-100">
                            <label>Company name</label>
                            <input className="inputs w-100" name="com" value={form.com} onChange={changeForm}/>
                            {errors.com && <div className='error'>{errors.com}</div>}
                        </div>
                        <div className="w-100">
                            <label>Company code</label>
                            <input className="inputs w-100" name="comcode" value={form.comcode} onChange={changeForm}/>
                            {errors.comcode && <div className='error'>{errors.comcode}</div>}
                        </div>
                      </div>
                      <div>
                          <label>Country</label>
                          <CountrySelector setData={changeCountry} data={form.country}/>
                          {errors.country && <div className='error'>{errors.country}</div>}
                      </div>
                      <div className='d-flex gap-3 flex-column flex-md-row'>
                        <div className="w-100">
                            <label>Phone</label>
                            <input className="inputs w-100" name="phone" value={form.phone} onChange={changeForm}/>
                            {errors.phone && <div className='error'>{errors.phone}</div>}
                        </div>
                        <div className="w-100">
                            <label>Email</label>
                            <input className="inputs w-100" name="email" value={form.email} onChange={changeForm}/>
                            {errors.email && <div className='error'>{errors.email}</div>}
                        </div>
                      </div>
                      <div className="button mt-4" onClick={()=>sendForm()} disabled={send}>Send</div>
                      {errors.send && <div className='error text-center'>{errors.send}</div>}
                  </div>
              </div>
          </form>
          :<div className='p-5 text-center'>
            <h2>Thank you!</h2>
            <div>Your request was sent!</div>
          </div>
        }
      </ModalContainer>
    </ModalOverlay>
  );
};

const Desease = styled.div`
    font-weight:500;
    color:var(--purple-dark);
`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  padding:10px;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: #fff;
  border-radius: 24px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width:800px;
  .modal-block{
    padding: 30px 40px;
    padding-bottom:40px;
  }
`;

const CloseButton = styled.div`
    height:16px;
    width:16px;
    position:absolute;
    top:15px;
    right:15px;
    background:url(/img/close.svg) no-repeat 50% 50%;
    background-size:cover;
    cursor:pointer;
`

export {SendForm};
