import { useEffect, useState } from "react";
import { Desease } from "./Desease";
import { Modal } from "./Modal";
import { AddForm } from "./AddForm";
import { useTable } from "../hook/useTable";
import { List } from "./List";

const Line = ({ data,categoryId,sapp}) => {
  const [deseaseData, setDeseaseData] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);

  const {table,setTable} = useTable();
  
  const handleOpenModal = () => {
    setDeseaseData(null);
    setIsModalOpen(true);
  };

  const handleOpenList = () => {
    setIsListOpen(true);
  }
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseList = () => {
    setIsListOpen(false);
  };

  useEffect(() => {
    if (isModalOpen || isListOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen,isListOpen]);

  const openEdit = value => {
    setDeseaseData(value);
    setIsModalOpen(true);
  }

  const delItem = selectedIndex =>{
    const newTable = table.map(item => 
      ({
        ...item,
        desease:item.desease.map((desease, index) => {
          if (desease.id === data.id) {
            return {
              ...desease,
              selected: desease.selected.filter((k,i) => i !== selectedIndex)
            };
          }
          return desease;
        })
      })
    )
    setTable(newTable);
  }

  const addItem = newItem =>{
    const newTable = table.map(item => 
      ({
        ...item,
        desease: item.desease.map(desease => {
          if (desease.id === data.id) {
            return {
              ...desease,
              selected: [...desease.selected, newItem]
            };
          }
          return desease;
        })
      })
    )
    setTable(newTable);
    setIsModalOpen(false);
  }

  const saveItem = (selectedIndex,newData) =>{
  
    const newTable = table.map(item => 
      ({
        ...item,
        desease: item.desease.map(desease => {
          if (desease.id === data.id) {
            // Оновлюємо об'єкт в масиві selected
            return {
              ...desease,
              selected: desease.selected.map((selected, index) => {
                if (index === selectedIndex) {
                  return { ...selected, ...newData }; // Оновлюємо дані
                }
                return selected; // Повертаємо незмінений об'єкт
              })
            };
          }
          return desease; // Повертаємо незмінений об'єкт
        })
      })
    )
    setTable(newTable);
    setIsModalOpen(false);
    setIsListOpen(true);
    
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      >
        <AddForm 
          deseaseData={deseaseData} 
          addItem={addItem} 
          saveItem={saveItem}
        />
      </Modal>
      <Modal
        isOpen={isListOpen}
        onClose={handleCloseList}
      >
          <List
            data={data}
            openEdit={openEdit}
            setIsListOpen={setIsListOpen}
            delItem={delItem}/>
      </Modal>
      <Desease
        data={data}
        handleOpenModal={handleOpenModal}
        handleOpenList={handleOpenList}
        categoryId={categoryId}
        sapp={sapp}
      />
    </>
  );
};

export { Line };
